import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import App from './App';

const container = document.getElementById('react-root');
const root = container !== null ? createRoot(container) : null;

const initializeLDProvider = async () => {
  if (environment.LAUNCHDARKLY_CLIENT_SIDE_ID) {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: environment.LAUNCHDARKLY_CLIENT_SIDE_ID,
    });
    return LDProvider;
  } else {
    return ({ children }: any) => <>{children}</>;
  }
};

initializeLDProvider().then((LDProvider) => {
  root?.render(
    <LDProvider>
      <App />
    </LDProvider>,
  );
});
