import { CSSProperties, ReactNode, SVGProps } from 'react';

export interface IconProps extends SVGProps<SVGSVGElement> {
  children: ReactNode;
  color?: string;
  size?: string | number;
  width?: string | number;
  height?: string | number;
  opacity?: string | number;
  style?: CSSProperties;
}

const IconBase = ({ children, color, size, style, width, height, opacity = 0.84, ...props }: IconProps) => {
  const computedSize = size || '1em';
  return (
    <svg
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      height={height || computedSize}
      width={width || computedSize}
      {...props}
      style={{
        opacity,
        verticalAlign: 'middle',
        color,
        ...style,
      }}
    >
      {children}
    </svg>
  );
};

export default IconBase;
